import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import SliceZone from '../components/SliceZone'
import SEO from '../components/SEO/seo'


export default ({ data }) => {
  if (!data) return null;
  const doc = data.prismic.allContact_pages.edges[0]
  const slices = doc.node.body

  return (
    <>
      <SEO title='Contact' />
      <Root>
        <SliceZone slices={slices} />
      </Root>
    </>
  )
}

const Root = styled.div`
  a {
    font-family: 'Futura Bold';
  }
  p {
    text-align: center;
  }
`;

export const query = graphql`
query ContactPageQuery($lang: String) {
  prismic {
    allContact_pages(lang: $lang) {
      edges {
        node {
          _meta {
            lang
            type
            uid
            alternateLanguages {
              lang
              type
              uid
            }
          }
          body {
            ... on PRISMIC_Contact_pageBodyImage_with_text {
              type
              label
              primary {
                image
                text
              }
            }
          }
        }
      }
    }
  }
}
`